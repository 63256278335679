// Import our CSS
import 'aos/src/sass/aos.scss';
import iskstyles from '../scss/app.scss';

// import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'

//Import vue components
import 'promise-polyfill/src/polyfill';
import Vuex from 'vuex'
import AOS from 'aos';
import { mixin as clickaway } from 'vue-clickaway'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueYoutube from 'vue-youtube'
import VueVideoSection from 'vue-video-section'
import SkyReveal from 'sky-reveal';
import VueCookies from 'vue-cookies'

// App main
const main = async () => {
	// Async load the vue module
	const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

	// USE
	Vue.use(Vuex);
  Vue.use(SkyReveal);
	Vue.use(VueAwesomeSwiper);
	Vue.use(VueYoutube)
  Vue.use(VueCookies)

	const store = new Vuex.Store({
		state: {
      showPrivacyPopup: false
		},
		mutations: {
			setshowPrivacyPopup(state, value) {
				state.showPrivacyPopup = value
			},
		}
	});

	// Create our vue instance
	// @ts-ignore
	const vm = new Vue({
		el: "#app",
		delimiters: ['${', '}'],
		store,
		components: {
      // General components
			'Accordion': () => import('./components/Accordion.vue'),
			'Overview': () => import('./components/Overview.vue'),
			'YoutubeVideo': () => import('./components/YoutubeVideo.vue'),
			'WebinarVideo': () => import('./components/WebinarVideo.vue'),
			'SiteMenu': () => import('./components/SiteMenu.vue'),
      'EventSignup': () => import('./components/EventSignup.vue'),

      // Templates
			'Guide': () => import('./components/guide/Guide.vue'),
			'GuidePage': () => import('./components/guide/GuidePage.vue'),
			'GuideNavigation': () => import('./components/guide/objects/GuideNavigation.vue'),
			'GuideReturn': () => import('./components/guide/objects/GuideReturn.vue'),

      // External
			VueVideoSection,
		},
		mixins: [clickaway],
		data() {
			return {
        showBanner: true,
        mountVideo: false
			}
		},
		props: {
		},
		methods: {
      togglePrivacyPopup() {
				store.commit('setshowPrivacyPopup', !store.state.showPrivacyPopup)
			},
		},
		computed: {

		},
		created() {
			// AOS.init({
			// 	easing: 'ease-in-out',
			// 	delay: 0,
			// 	once: true,
			// 	startEvent: 'load',
			// 	anchorPlacement: 'top-bottom'
			// })
		},
		mounted() {
			this.$nextTick(function () {
				this.mountVideo = true
				window.addEventListener('load', () => {
					let player = this.$refs['vue-video'];

					if (player) {
						this.$refs['vue-video'].playVideo().then(() => {

							this.$nextTick(function () {
								this.showBanner = false
							})

						}
						)
					}
				})
			})
		},
	});

	return vm;
};

// Execute async function
main().then((vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
